import clsx from 'clsx'
import Image from 'next/image'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { LinkType } from '../../generated/contember'
import type { PostItemResult } from '../data/PostFragment'
import type { RecipeItemResult } from '../data/RecipeFragment'
import styles from './Blogs.module.sass'
import { Link } from './Link'

export function Blogs(props: {
	posts?: PostItemResult[]
	recipes?: RecipeItemResult[]
	isListPage?: boolean
	headline?: string
}) {
	const items = (
		props.isListPage && props.posts
			? props.posts
			: props.isListPage && props.recipes
			? props.recipes
			: props.posts && props.recipes
			? [props.posts[0], props.recipes[0]]
			: []
	).filter((item): item is NonNullable<typeof item> => !!item)

	return (
		<>
			{!!items.length && (
				<div className={clsx(styles.wrapper)}>
					<>
						<AnimationOnScroll animateIn="animate__fadeInUp" animateOnce initiallyVisible={props.isListPage}>
							{props.headline && <h2>{props.headline}</h2>}
						</AnimationOnScroll>
						<div className={styles.inner}>
							{items.map((item, index) => {
								return (
									<AnimationOnScroll
										animateOnce
										className={styles.item}
										key={index}
										animateIn="animate__fadeInUp"
										initiallyVisible={props.isListPage && index < 2}
									>
										<a href={item.link?.url}></a>
										<div className={styles.content}>
											<h3>
												{item.title}
												<br></br>
												<span>
													{item.publishedAt && new Date(item.publishedAt).toLocaleDateString('cs')} by {item.author}
												</span>
											</h3>
										</div>
										<div className={styles.image}>
											{item.thumbnail && (
												<Image
													src={item.thumbnail.url}
													alt={item.thumbnail.alt ?? ''}
													width={item.thumbnail.width}
													height={item.thumbnail.height}
												/>
											)}
										</div>
									</AnimationOnScroll>
								)
							})}
						</div>
					</>
					{!props.isListPage && (
						<div className={styles.btns}>
							{!!props.posts?.length && (
								<Link
									isBtn
									link={{ internalLink: { url: '/blog' }, title: 'Přejít na blog', type: LinkType.internal }}
								/>
							)}
							{!!props.recipes?.length && (
								<Link
									isBtn
									link={{ internalLink: { url: '/recepty' }, title: 'Přejít na recepty', type: LinkType.internal }}
								/>
							)}
						</div>
					)}
				</div>
			)}
		</>
	)
}
