import { One, OrderDirection } from '../../generated/contember'
import { Blogs } from '../components/Blogs'
import { Container } from '../components/Container'
import { ContentRenderer } from '../components/ContentRenderer'
import { Footer } from '../components/Footer'
import { Nav } from '../components/Nav'
import { Seo } from '../components/Seo'
import { Stain } from '../components/Stain'
import { GeneralFragment } from '../data/GeneralFragment'
import { GenericPageFragment } from '../data/GenericPageFragment'
import { ImageFragment } from '../data/ImageFragment'
import { LinkFragment } from '../data/LinkFragment'
import { MenuFragment } from '../data/MenuFragment'
import { PostFragment } from '../data/PostFragment'
import { RecipeFragment } from '../data/RecipeFragment'
import { contember } from '../utilities/contember'
import { contemberLinkToHref } from '../utilities/contemberLinkToHref'
import { scalarResolver } from '../utilities/createScalarResolver'
import { getLinkableUrlFromContext } from '../utilities/getLinkableUrlFromContext'
import type { InferDataLoaderProps } from '../utilities/handlers'
import { handleGetStaticPaths, handleGetStaticProps } from '../utilities/handlers'

export type PageProps = InferDataLoaderProps<typeof getStaticProps>

export default function ({ footer, page, seo, menu, posts, recipes, url }: PageProps) {
	return (
		<>
			<Seo {...seo} />
			{menu?.items && <Nav menuItems={menu?.items} activeUrl={url} />}
			{page && <>{page.content && <ContentRenderer content={page.content} />}</>}
			<Stain />
			<Stain left />
			<Container>
				<Blogs posts={posts} recipes={recipes} headline="Přečtěte si:" />
			</Container>
			<Footer
				desc={footer?.text}
				email={footer?.email}
				image={footer?.image}
				instagramNick={footer?.ig}
				title={footer?.headline}
				link={footer?.primaryLink}
				secondaryLink={footer?.secondaryLink}
				additionalNote={footer?.additionalNote}
			/>
		</>
	)
}

export const getStaticPaths = handleGetStaticPaths(async (context) => {
	const { listLinkable } = await contember('query', { scalars: scalarResolver })({
		listLinkable: [
			{
				filter: {
					redirect: { id: { isNull: true } },
					post: { id: { isNull: true } },
					recipe: { id: { isNull: true } },
				},
			},
			{
				id: true,
				url: true,
			},
		],
	})

	return {
		paths: listLinkable.map((link) => {
			const path = link.url.split('/').filter((part) => part !== '')

			let locale: string | undefined

			if (context.locales?.includes(path[0])) {
				locale = path.shift()
			}

			return {
				locale,
				params: {
					path,
				},
			}
		}),
		fallback: 'blocking',
	}
})

export const getStaticProps = handleGetStaticProps(async (context) => {
	const url = getLinkableUrlFromContext(context)

	const data = await contember('query', { scalars: scalarResolver })({
		getGeneral: [
			{
				by: {
					unique: One.One,
				},
			},
			GeneralFragment(),
		],
		listPost: [{ orderBy: [{ publishedAt: OrderDirection.asc }], limit: 3 }, PostFragment()],
		listRecipe: [{ orderBy: [{ publishedAt: OrderDirection.asc }], limit: 3 }, RecipeFragment()],
		getFooter: [
			{
				by: {
					unique: One.One,
				},
			},
			{
				email: true,
				headline: true,
				text: true,
				ig: true,
				primaryLink: [{}, LinkFragment()],
				secondaryLink: [{}, LinkFragment()],
				image: [{}, ImageFragment()],
				additionalNote: true,
			},
		],
		getMenu: [
			{
				by: {
					unique: One.One,
				},
			},
			MenuFragment(),
		],
		getLinkable: [
			{
				by: { url },
				filter: {
					post: { id: { isNull: true } },
				},
			},
			{
				url: true,
				genericPage: [{}, GenericPageFragment()],
				redirect: [
					{},
					{
						id: true,
						target: [{}, LinkFragment()],
					},
				],
			},
		],
	})

	const redirectUrl = (() => {
		const target = data.getLinkable?.redirect?.target
		return target ? contemberLinkToHref(target) : null
	})()

	if (redirectUrl) {
		return {
			redirect: {
				permanent: false,
				destination: redirectUrl,
			},
			revalidate: 60,
		}
	}

	const canonicalUrl = (() => {
		const url = data.getLinkable?.url
		if (!url) {
			return null
		}
		return (process.env.NEXT_PUBLIC_WEB_URL ?? '') + url
	})()

	const page = data.getLinkable?.genericPage

	if (!page) {
		return {
			notFound: true,
			revalidate: 60,
		}
	}

	return {
		props: {
			general: data.getGeneral,
			footer: data.getFooter,
			menu: data.getMenu,
			page,
			url: data.getLinkable?.url,
			posts: data.listPost,
			recipes: data.listRecipe,
			seo: {
				canonicalUrl,
				seo: {
					...(data.getGeneral?.seo ?? {}),
					...Object.fromEntries(Object.entries(page.seo ?? {}).filter(([_, value]) => Boolean(value))),
				},
			},
		},
		revalidate: 60,
	}
})
